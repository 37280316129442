import joinClassNames from "classnames";

import CurveArrowIcon from "icons/curve-arrow.svg?react";

import globalClasses from "styles/classes.module.scss";

import PaymentMethods from "./components/payment-methods";
import MobileLinks from "./components/mobile-links";
import BuyForm from "./components/buy-form";

import mainClasses from "../../styles/classes.module.scss";
import classes from "./styles/classes.module.scss";

const BuyCrypto = () => (
  <section className={joinClassNames(mainClasses.section, classes.section)}>
    <div className={classes.backgroundsWrapper}>
      <div className={classes.cryptoIconsWrapper} />
      <div className={classes.buyCryptoWrapper}>
        <h1
          className={joinClassNames(globalClasses.gradientTitle, classes.title)}
        >
          The Best Way To Buy&nbsp;Crypto!
        </h1>
        <p className={classes.description}>
          Get the best rates from top providers like Revolut, Stripe, Moonpay,
          and Ramp. Choose your preferred payment method, currency, and country,
          and buy crypto in the most cost-effective way — all in one&nbsp;app.
        </p>
        <MobileLinks />
      </div>
    </div>
    <div className={classes.overlayedContentWrapper}>
      <MobileLinks isQR />
      <BuyForm />
      <div className={classes.countries}>
        <div className={classes.countriesLabel}>
          <CurveArrowIcon className={classes.arrowIcon} />
          <p className={classes.text}>150+ countries available</p>
        </div>
        <PaymentMethods />
      </div>
    </div>
  </section>
);

export default BuyCrypto;
