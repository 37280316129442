import SuccessIcon from "icons/success.svg?react";

import Button from "basics/button";

import { useDialog } from "components/dialog";

import classes from "./styles/classes.module.scss";

const Success = () => {
  const { resolve } = useDialog();

  return (
    <div className={classes.wrapper}>
      <SuccessIcon />
      <h3 className={classes.title}>Thank you!</h3>
      <p className={classes.subTitle}>We will contact you soon</p>
      <Button themeName="primary" isBlock onClick={() => resolve(null)}>
        Done
      </Button>
    </div>
  );
};

export default Success;
