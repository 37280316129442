import Button from "basics/button";

import {
  DISPLAYABLE_PAYMENT_METHODS_COUNT,
  PAYMENT_METHOD_ICONS,
} from "./duck/constants";
import { openPaymentMethodsModal } from "./components/payment-methods-modal";

import classes from "./styles/classes.module.scss";

const PaymentMethods = () => {
  const restPaymentMethodsCount =
    PAYMENT_METHOD_ICONS.length - DISPLAYABLE_PAYMENT_METHODS_COUNT;

  return (
    <div className={classes.wrapper}>
      <p className={classes.label}>More&nbsp;than 25&nbsp;payment methods</p>
      <div className={classes.divider} />
      <div className={classes.paymentMethodsWrapper}>
        {PAYMENT_METHOD_ICONS.slice(0, DISPLAYABLE_PAYMENT_METHODS_COUNT).map(
          Icon => (
            <div
              title={Icon.title}
              key={Icon.title}
              className={classes.iconWrapper}
            >
              <Icon className={classes.icon} />
            </div>
          ),
        )}
        <Button
          className={classes.openModalButton}
          onClick={() => {
            openPaymentMethodsModal();
          }}
        >
          +{restPaymentMethodsCount}
        </Button>
      </div>
    </div>
  );
};

export default PaymentMethods;
