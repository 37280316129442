import { Bitcoin, DefaultCoinNames, Ethereum } from "constants/coins";

export const DEFAULT_CURRENCY = {
  id: 3,
  name: "USD",
  symbol: "$",
  icon: "https://api-staging.coindisco.com/media/currencies/icons/USD.png",
  isPopular: true,
  default: true,
};

export const FALLBACK_INSIGHTS_INFO = {
  // Prod: 173, Dev: 132
  regionId: 132,
  currencyId: DEFAULT_CURRENCY.id,
};

export const DEFAULT_PAYMENT_METHODS: PaymentOption[] = [
  {
    id: 1,
    code: "ach_bank_transfer",
    name: "ACH Payment Method",
    category: "Bank transfer",
    categoryDescription: "Lowest fee",
    isDefault: true,
    regionId: FALLBACK_INSIGHTS_INFO.regionId,
  },
  {
    id: 6,
    code: "sepa_bank_transfer",
    name: "SEPA bank transfer",
    category: "Bank transfer",
    categoryDescription: "Lowest fee",
    regionId: FALLBACK_INSIGHTS_INFO.regionId,
  },
  {
    id: 3,
    code: "gbp_bank_transfer",
    name: "GBP bank transfer",
    category: "Bank transfer",
    categoryDescription: "Lowest fee",
    regionId: FALLBACK_INSIGHTS_INFO.regionId,
  },
  {
    id: 10,
    code: "mobile_wallet",
    name: "Google Pay",
    category: "Mobile Wallets",
    categoryDescription: "Instant payment",
    regionId: FALLBACK_INSIGHTS_INFO.regionId,
  },
  {
    id: 5,
    code: "mobile_wallet",
    name: "Apple Pay",
    category: "Mobile Wallets",
    categoryDescription: "Instant payment",
    regionId: FALLBACK_INSIGHTS_INFO.regionId,
  },
  {
    id: 43,
    code: "revolut",
    name: "Revolut",
    category: "Bank transfer",
    categoryDescription: "Lowest fee",
    regionId: FALLBACK_INSIGHTS_INFO.regionId,
  },
];

export const DEFAULT_CRYPTOCURRENCIES: Record<
  DefaultCoinNames,
  Cryptocurrency
> = {
  [DefaultCoinNames.Bitcoin]: {
    id: 1,
    name: "Bitcoin",
    symbol: "btc",
    letterId: "btc",
    rank: 1,
    logo: "https://s3.us-east-1.amazonaws.com/cdn-staging.coindisco.com/currencies/logo/btc_bitcoin.png",
    buyAvailable: true,
    isFavorite: true,
    networks: [
      {
        id: Bitcoin.networkId,
        name: "Bitcoin",
        code: "bitcoin",
        regex: "^[13][a-km-zA-HJ-NP-Z1-9]{25,34}$|^(bc1)[0-9A-Za-z]{39,59}$",
      },
    ],
  },
  [DefaultCoinNames.Ethereum]: {
    id: 2,
    name: "Ethereum",
    symbol: "eth",
    letterId: "eth",
    rank: 2,
    logo: "https://s3.us-east-1.amazonaws.com/cdn-staging.coindisco.com/currencies/logo/eth_ethereum.png",
    buyAvailable: true,
    isFavorite: false,
    networks: [
      {
        id: Ethereum.networkId,
        name: "Ethereum",
        code: "ethereum",
        regex: "^(0x)[0-9A-Fa-f]{40}$",
      },
    ],
  },
};
