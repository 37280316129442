import Button, { Sizes } from "basics/button";

import classes from "./styles/classes.module.scss";

interface Props {
  currency: Currency;
  cryptocurrency: Cryptocurrency;
  submitButtonSize: Sizes;
}

const SubmitButton = ({
  submitButtonSize,
  currency,
  cryptocurrency,
}: Props) => (
  <Button
    size={submitButtonSize}
    className={classes.button}
    type="submit"
    disabled={!currency || !cryptocurrency}
    themeName="primary"
  >
    Buy
  </Button>
);

export default SubmitButton;
