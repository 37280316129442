import { FC, Fragment } from "react";

import { PaymentMethodInfo } from "../../duck/types";

import classes from "./styles/classes.module.scss";

const PaymentMethod: FC<PaymentMethodInfo> = ({
  name,
  image,
  currency,
  paymentMethods,
}) => (
  <div className={classes.wrapper}>
    <div className={classes.regionInfo}>
      <img
        className={classes.flagImage}
        src={`https://api-staging.coindisco.com/media/service_integration/Region/${image}`}
        alt={`${name} flag`}
      />
      <p className={classes.region}>{name}</p>
      <div className={classes.dropdownTrigger}>
        <div className={classes.dot} />
        <div className={classes.dot} />
        <div className={classes.dot} />
      </div>
    </div>
    <div className={classes.currencyInfo}>
      <p>{currency}</p>
      <div className={classes.paymentOptionsWrapper}>
        {paymentMethods.map(({ icon: Icon, title }) => (
          <Fragment key={title}>
            <Icon className={classes.paymentMethodIcon} />
            {title}
          </Fragment>
        ))}
      </div>
    </div>
  </div>
);

export default PaymentMethod;
