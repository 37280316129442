import { ChangeEvent, ComponentType, Key } from "react";
import joinClassNames from "classnames";
import isObject from "lodash/isObject";
import { UseFormRegisterReturn } from "react-hook-form";

import { ItemType } from "./duck/types";

import classes from "./styles/classes.module.scss";

interface Props<T, K> {
  value: string;
  items: T[];
  option: ComponentType<{ item: T }>;
  className?: string;
  classNames?: Partial<{ item: string; title: string }>;
  optionKey?: K;
}

interface PropsCommon<T, K> extends Props<T, K> {
  registration?: never;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

interface PropsHookForm<T, K> extends Props<T, K> {
  registration: UseFormRegisterReturn;
  onChange?: never;
}

function Radio<T extends ItemType, K extends keyof T>({
  value,
  items,
  option: Option,
  optionKey,
  className,
  registration,
  classNames = {},
  onChange,
}: PropsCommon<T, K> | PropsHookForm<T, K>) {
  const valueKey = (optionKey || "value") as keyof T;

  return (
    <div className={joinClassNames(classes.list, className)}>
      {items.map(item => {
        const itemValue = isObject(item) ? item[valueKey] : (item as string);
        const id = `radio-${itemValue}`;

        return (
          <label key={itemValue as Key} htmlFor={id} className={classes.label}>
            <input
              id={id}
              value={itemValue}
              type="radio"
              onChange={onChange}
              {...registration}
              className={classes.input}
            />
            <div
              className={joinClassNames(classes.item, classNames.item)}
              aria-selected={value === itemValue}
            >
              <Option item={item} />
            </div>
          </label>
        );
      })}
    </div>
  );
}

export default Radio;
