import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import isEmpty from "lodash/isEmpty";

import integrationAPI from "api/integration";

import { Modal, openAlert } from "components/dialog";

import Input from "basics/input";
import Button from "basics/button";

import { getErrorType, getFieldError } from "selectors/errors";

import Success from "./components/success";
import RadioGroup from "./components/radio-group";

import { TEXT_ERRORS } from "./duck/constants";
import { FormValues } from "./duck/types";

import classes from "./styles/classes.module.scss";

const ApplicationRequestModal = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const {
    handleSubmit,
    register,
    watch,
    setError,
    formState: { isSubmitting, errors, isValid },
  } = useForm<FormValues>({
    defaultValues: {
      integrationType: null,
      productName: "",
      productCategory: null,
      name: "",
      email: "",
      company: "",
    },
  });

  const submitHandler: SubmitHandler<FormValues> = async ({ ...fields }) => {
    let isError = false;
    Object.entries(fields).forEach(([fieldKey, fieldValue]) => {
      const textFieldError = TEXT_ERRORS[fieldKey];
      if (textFieldError && fieldValue.length > textFieldError.maxLength) {
        isError = true;
        setError(
          fieldKey as keyof FormValues,
          {
            message: `${textFieldError.name} should not contain more than ${textFieldError.maxLength} symbols`,
          },
          { shouldFocus: true },
        );
      }
    });

    if (!isError) {
      try {
        await integrationAPI.createIntegration(fields);
        setIsSuccess(true);
      } catch (error) {
        if (getErrorType(error)) {
          setError(
            "email",
            {
              message: getFieldError(error, "email"),
            },
            { shouldFocus: true },
          );
        } else {
          openAlert({
            message: "An error occurred while submitting the form",
          });
        }
      }
    }
  };

  return (
    <Modal>
      <Modal.Header>{isSuccess ? "" : "Application form"}</Modal.Header>
      {isSuccess ? (
        <Success />
      ) : (
        <form className={classes.form} onSubmit={handleSubmit(submitHandler)}>
          <section>
            <h3 className={classes.sectionTitle}>Select integration type</h3>
            <RadioGroup
              name="integrationType"
              watch={watch}
              register={register}
            />
          </section>
          <section>
            <h3 className={classes.sectionTitle}>Product name</h3>
            <Input
              size="sm"
              placeholder="Product name"
              error={errors?.productName?.message}
              {...register("productName", { required: true })}
            />
          </section>
          <section>
            <h3 className={classes.sectionTitle}>Select Product Category</h3>
            <RadioGroup
              name="productCategory"
              watch={watch}
              register={register}
            />
          </section>
          <section>
            <h3 className={classes.sectionTitle}>Contact information</h3>
            <Input
              size="sm"
              label="Name"
              placeholder="Full name"
              error={errors?.name?.message}
              {...register("name", { required: true })}
            />
            <Input
              size="sm"
              label="Email"
              placeholder="youremail@example.com"
              error={errors?.email?.message}
              {...register("email", { required: true })}
            />
            <Input
              size="sm"
              label="Company"
              placeholder="Company name"
              error={errors?.company?.message}
              {...register("company", { required: true })}
            />
          </section>
          <Button
            type="submit"
            themeName="primary"
            isBlock
            isLoading={isSubmitting}
            disabled={!isEmpty(errors) || !isValid || isSubmitting}
          >
            Submit request
          </Button>
        </form>
      )}
    </Modal>
  );
};

export default ApplicationRequestModal;
