import joinClassNames from "classnames";

import RoundedArrow40Icon from "icons/rounded-arrow40.svg?react";

import globalClasses from "styles/classes.module.scss";

import { BUY_CARDS_INFO, BUY_LINKS } from "./duck/constants";

import classes from "./styles/classes.module.scss";

const BuyPopularCrypto = () => (
  <section className={classes.section}>
    <div className={classes.contentWrapper}>
      <h2
        className={joinClassNames(globalClasses.gradientTitle, classes.title)}
      >
        Buy Your Favorite Crypto, Fast & Easy
      </h2>
      <p className={classes.description}>
      Trade Bitcoin, Ethereum, USDC, Solana, and more with top providers and real-time pricing.
      </p>
      <div className={classes.buyCryptoCards}>
        {BUY_CARDS_INFO.map(({ name, type, url }) => (
          <a
            key={name}
            data-theme={name}
            className={joinClassNames(
              classes.themePresets,
              classes.buyCryptoCard,
            )}
            href={url}
            target="_blank"
          >
            <p className={classes.type}>{type}</p>
            <div className={classes.nameWrapper}>
              <p className={classes.name}>Buy {name}</p>
              <RoundedArrow40Icon className={classes.chevronWrapper} />
            </div>
          </a>
        ))}
      </div>
      <div className={classes.buyLinks}>
        {BUY_LINKS.map(({ name, code, url }) => (
          <a className={classes.buyLink} key={name} href={url} target="_blank">
            {name} ({code})
          </a>
        ))}
      </div>
    </div>
  </section>
);

export default BuyPopularCrypto;
