import { defer } from "@remix-run/cloudflare";

import BuyCrypto from "./components/buy-crypto";
import DiscoverTrueFee from "./components/discover-true-fee";
import IntelligentBuying from "./components/intelligent-buying";
import TrustedPartners from "./components/trusted-partners";
import PaymentMethods from "./components/payment-methods";
import Insights from "./components/insights";
import BuyPopularCrypto from "./components/buy-popular-crypto";
import Integration from "./components/integration";
import { Loader } from "./duck/types";

import {
  fetchCryptocurrencies,
  fetchCurrencies,
  fetchInsights,
  fetchPaymentMethods,
  fetchQuotes,
  fetchRegionId,
} from "./duck/operations";

import classes from "./styles/classes.module.scss";

const Landing = () => (
  <main className={classes.main} data-fullscreen>
    <BuyCrypto />
    <DiscoverTrueFee />
    <IntelligentBuying />
    <Integration />
    <TrustedPartners />
    <PaymentMethods />
    <Insights />
    <BuyPopularCrypto />
  </main>
);

export const loader: Loader = () => {
  const regionId = fetchRegionId();
  const paymentMethods = fetchPaymentMethods(regionId);
  const insights = fetchInsights(paymentMethods);

  return defer({
    insights,
    currencies: fetchCurrencies(regionId),
    quotes: fetchQuotes(insights),
    cryptocurrencies: fetchCryptocurrencies(),
  });
};

export default Landing;
