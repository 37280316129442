import joinClassNames from "classnames";

import RoutingIcon from "icons/routing.svg?react";

import globalClasses from "styles/classes.module.scss";

// import LearnLink from "components/learn-link/LearnLink";

import mainClasses from "../../styles/classes.module.scss";
import classes from "./styles/classes.module.scss";

const IntelligentBuying = () => (
  <section className={joinClassNames(mainClasses.section, classes.section)}>
    <div className={classes.contentWrapper}>
      <div className={classes.iconWrapper}>
        <RoutingIcon className={classes.routingIcon} />
      </div>
      <div className={classes.descriptionWrapper}>
        <h2
          className={joinClassNames(globalClasses.gradientTitle, classes.title)}
        >
          The Smartest Way to Buy Crypto
        </h2>
        <p className={mainClasses.description}>
          Our advanced engine automatically finds the fastest and cheapest way
          to buy crypto based on your location, payment method, and currency.
          Focus on investing, not comparing providers.
        </p>
        {/*<LearnLink>Learn more</LearnLink>*/}
      </div>
    </div>
  </section>
);

export default IntelligentBuying;
