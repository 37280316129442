import CircledIcon, { CoinIcon } from "components/icon";

import { RenderButton, RenderButtonProps } from "../../duck/types";
import { Value } from "./duck/types";

import classes from "./styles/classes.module.scss";

const Button: RenderButton<Value, { iconType?: "circle" | "coin" }> = ({
  selected,
  label,
  iconType = "circle",
}) => {
  const IconComponent = iconType === "circle" ? CircledIcon : CoinIcon;

  return (
    <div className={classes.wrapper}>
      {label && <p className={classes.label}>{label}</p>}
      {selected ? (
        <>
          <IconComponent
            className={classes.icon}
            src={selected.icon || selected.logo}
          />
          <p className={classes.text}>{selected.name}</p>
        </>
      ) : (
        <p className={classes.text}>Choose option</p>
      )}
    </div>
  );
};

export const ButtonCoin = (props: RenderButtonProps<Value>) => (
  <Button iconType="coin" {...props} />
);

export default Button;
