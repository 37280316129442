import Tick16Icon from "icons/tick16.svg?react";

import classes from "./styles/classes.module.scss";

const Advantages = () => (
  <div className={classes.wrapper}>
    {[
      "Fast, plug-and-play integration via widget or API",
      "Save development time and boost conversions",
      "Top providers available in 150+ countries",
    ].map(text => (
      <div className={classes.text} key={text}>
        <div className={classes.tick}>
          <Tick16Icon />
        </div>
        {text}
      </div>
    ))}
  </div>
);

export default Advantages;
