import { FC } from "react";
import { UseFormWatch, UseFormRegister } from "react-hook-form";

import Radio, { RadioDot } from "basics/radio";

import { FormValues } from "../../duck/types";

import { RADIO_GROUPS } from "./duck/constants";

import classes from "./styles/classes.module.scss";

interface Props {
  name: "integrationType" | "productCategory";
  watch: UseFormWatch<FormValues>;
  register: UseFormRegister<FormValues>;
}

const RadioGroup: FC<Props> = ({ name, watch, register }) => {
  const value = watch(name);

  return (
    <Radio
      value={value}
      items={RADIO_GROUPS[name]}
      option={({ item }) => (
        <>
          <RadioDot size="sm" />
          <div className={classes.itemContent}>{item.label}</div>
        </>
      )}
      className={classes.list}
      classNames={{ item: classes.listItem }}
      registration={register(name, { required: true })}
    />
  );
};

export default RadioGroup;
