import Start120Icon from "icons/star120.svg?react";

import { DOTS_COLORS, PLATFORMS } from "./duck/constants";

import classes from "./styles/classes.module.scss";

const Integration = () => (
  <div className={classes.wrapper}>
    <div className={classes.connections} />
    <div className={classes.apiWrapper}>
      <div className={classes.connectionsLeft} />
      <div className={classes.api}>
        <div className={classes.apiBackground}>
          <p className={classes.apiTitle}>Coindisco</p>
          <Start120Icon className={classes.apiIcon} />
          <p className={classes.apiSubtitle}>API</p>
        </div>
      </div>
      <div className={classes.connectionsRight} />
    </div>
    <div className={classes.linesRight}>
      <div className={classes.linesRightFirst} />
      <div className={classes.linesRightSecond} />
    </div>
    <div className={classes.browser}>
      <div className={classes.browserHeader}>
        {DOTS_COLORS.map(item => (
          <div
            key={item}
            style={{ backgroundColor: item }}
            className={classes.dot}
          />
        ))}
      </div>
      <ul className={classes.browserList}>
        {PLATFORMS.map(item => (
          <li key={item} className={classes.listItem}>
            {item}
          </li>
        ))}
      </ul>
    </div>
  </div>
);

export default Integration;
