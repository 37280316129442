export const RADIO_GROUPS = {
  integrationType: [
    {
      value: "widget",
      label: "Widget",
    },
    {
      value: "api",
      label: "API",
    },
  ],
  productCategory: [
    {
      value: "wallet",
      label: "Wallet",
    },
    {
      value: "dex",
      label: "DEX",
    },
    {
      value: "dapp",
      label: "DApp",
    },
  ],
};
