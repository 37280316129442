import joinClassNames from "classnames";

import globalClasses from "styles/classes.module.scss";

import Button from "basics/button";

import Advantages from "./components/advantages";
import Scheme from "./components/scheme";
import { openApplicationRequestModal } from "./components/application-request-modal";

import mainClasses from "../../styles/classes.module.scss";
import classes from "./styles/classes.module.scss";

const Integration = () => (
  <section className={joinClassNames(mainClasses.section, classes.wrapper)}>
    <div className={classes.anchor} id="integration" />
    <div className={classes.titleWrapper}>
      <h2
        className={joinClassNames(globalClasses.gradientTitle, classes.title)}
      >
        Seamless Crypto <span className={classes.noWrap}>On/Off-Ramp</span>{" "}
        Integration for Your&nbsp;App
      </h2>
      <p className={mainClasses.description}>
        Integrate Coindisco into your application and enable users to buy and
        sell crypto at the best rates with the lowest fees — all in one place.
      </p>
    </div>
    <Scheme />
    <Advantages />
    <Button
      themeName="primary"
      className={classes.button}
      onClick={() => {
        openApplicationRequestModal();
      }}
    >
      Apply now
    </Button>
  </section>
);

export default Integration;
