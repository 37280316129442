import { instance } from "./instances/coindisco";

export interface CreateIntegrationDTO {
  integrationType: "widget" | "api";
  productName: string;
  productCategory: "wallet" | "dex" | "dapp";
  name: string;
  email: string;
  company: string;
}

const integrationAPI = new (class {
  public createIntegration(params: CreateIntegrationDTO) {
    return instance.post("service-integration/integration-requests/", params);
  }
})();

export default integrationAPI;
